import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAMap from 'vue-amap';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { post, get, upLoadImg, postFormData, getFormData } from './lib/ajax'
// import { downloadWithJsonParam, downloadWithFormData, getFileData } from './lib/downloadFile'
import echarts from "echarts";
import scroll from 'vue-seamless-scroll'

import VueVideoPlayer from 'vue-video-player/src'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer)
Vue.prototype.$echarts = echarts;
import vueEsign from 'vue-esign'
Vue.config.productionTip = false
Vue.use(vueEsign)
Vue.use(ElementUI);
Vue.use(scroll)
Vue.use(VueAMap);
// Vue.use(animated)
Vue.prototype.$echarts = echarts
Vue.prototype.$post = post
Vue.prototype.$get = get

// https://www.gzstjt.cn
Vue.prototype.$baseUrl = 'https://www.gzstjt.cn'
VueAMap.initAMapApiLoader({
  key: '859633454deb0ef6e9fc2c9ebd18e69e',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale',
    'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor',
    'AMap.CircleEditor', 'Geocoder', 'AMap.MarkerClusterer', 'AMap.AMapUI', 'AMapUI.loadUI'],
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


