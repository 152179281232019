<template>
  <div class="CommonBottom">
    <div class="Bottom">
      <div class="ledt">
        <el-image
          style="width: 446px; height: 58px;"
          :src="logo"
          fit="scale-down"
        ></el-image>
        <div class="copyright">
          网站版权归 贵州晟泰实业发展（集团）有限公司 所有
          <span></span>
          <span class="path" @click="goweb(1)">黔ICP备18007705号-1</span>
          <span class="path" @click="goweb(2)"
            >贵公网安备 52010302002745号</span
          >
          <span> 技术支持：晟泰集团 信息部</span>
        </div>
        <div class="phone">
          <span> 联系电话：0851-85822262</span>
          <span>联系QQ：564610780</span>
          <span>手机号码：18786627424</span>
          <span>联系邮箱：564610780@qq.com</span>
        </div>
      </div>
      <div class="right">
        <el-image
          style="width: 122px; height: 122px"
          :src="publics"
          fit="scale-down"
        ></el-image>
        <div class="publics">关注微信公众号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publics: require("@/assets/Image/public.png"),
      logo: require("@/assets/homeImage/top-logo.png"),
    };
  },
  methods: {
    goweb(type) {
      let url = "";
      if (type == 1) {
        url = "https://beian.miit.gov.cn/#/Integrated/index";
      } else {
        url = "https://www.beian.gov.cn/portal/index.do";
      }
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.CommonBottom {
  background: #f2f2f2;
  .Bottom {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 42px 0 64px 0;
    .copyright {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #343434;
      padding-top: 29px;
      span {
        margin-right: 40px;
      }
      .path {
        cursor: pointer;
      }
    }
    .phone {
      padding-top: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #343434;

      span {
        margin-right: 38px;
      }
    }
    .right {
      text-align: center;
      .publics {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #343434;
      }
    }
  }
}
</style>