<template>
  <div class="CommonCarousel">
    <el-carousel height="702px" @change="getIndex">
      <el-carousel-item v-for="(item, index) in bannerLiast" :key="index">
        <div class="CommonCarousel">
          <el-image
            style="width: 100%; height: 702px"
            :src="$baseUrl + item.img"
          ></el-image>

          <div class="fontbox" v-if="initial_index == index">
            <transition
              appear
              name="animate__animated animate__wobble"
              enter-active-class="animate__slideInLeft"
              leave-active-class="animate__slideOutDown"
            >
              <el-image :src="font" fit="scale-down" style="width: 920px;height: 468px">
              </el-image>
            </transition>

            <transition
              appear
              name="animate__animated animate__wobble"
              enter-active-class="animate__slideInRight"
              leave-active-class="animate__slideOutDown"
            >
              <div
                style="
                  font-size: 22px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                "
              >
                大展宏图共启航 · 鹏程九天凌云志 · 广育达人安泰平 ·
                齐聚贤才建晟业
              </div>
            </transition>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerLiast: [],
      initial_index: 0,
      font: require("@/assets/homeImage/font.png"),
    };
  },
  methods: {
    getbanner() {
      this.$get("/v1/banner/page").then((res) => {
        this.bannerLiast = res.items;
      });
    },
    getIndex(val) {
      this.initial_index = val;
    },
  },
  created() {
    this.getbanner();
  },
};
</script>

<style lang="less" scoped>
.CommonCarousel {
  height:702px;
  overflow: hidden;
  position: relative;
  .fontbox {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 2;
    // background-color: yellow;
  }
}
</style>