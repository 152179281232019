<template>
  <div class="CommonHeader">
    <div class="Header">
      <div class="logoBox">
        <el-image
          @click="goIndex"
          :src="ligoImg"
          style="width: 446px; height: 58px; cursor: pointer"
          fit="scale-down"
        ></el-image>
      </div>
      <div class="meun">
        <div
          v-for="(item, index) in meunList"
          :key="index"
          class="meunitem"
          @mouseenter="sidebar(item.path)"
          @mouseleave="sidebar2()"
        >
          <transition
            v-if="pathInfo == item.path"
            appear
            name="animate__animated animate__wobble"
            enter-active-class="animate__slideInLeft"
            leave-active-class="animate__slideOutLeft"
          >
            <div class="xian1"></div>
          </transition>
          <transition
            v-if="pathInfo == item.path"
            appear
            name="animate__animated animate__wobble"
            enter-active-class="animate__slideInRight"
            leave-active-class="animate__slideOutRight"
          >
            <div class="xian2"></div>
          </transition>
          <router-link :to="item.path">{{ item.name }} </router-link>
        </div>
        <!-- <router-link to="/" @mouseenter="sidebar()"></router-link>
        <router-link to="/news">新闻资讯 </router-link>
        <router-link to="/announcement">通知公告 </router-link>
        <router-link to="/about-us">关于我们</router-link>
        <router-link to="/business">经营范围</router-link>
        <router-link to="/enterprise">企业力量 </router-link>
        <router-link to="/download">数智晟泰 </router-link>
        <router-link to="/software">晟泰软件 </router-link>
        <router-link to="/contact"> 联系我们</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonHeader",
  data() {
    return {
      pathInfo: "",
      ligoImg: require("@/assets/homeImage/top-logo.png"),
      meunList: [
        { name: "首页", path: "/" },
        { name: "新闻资讯", path: "/news" },
        { name: "通知公告", path: "/announcement" },
        { name: "经营范围", path: "/business" },
        { name: "企业力量", path: "/enterprise" },
        { name: "数智晟泰", path: "/download" },
        { name: "联系我们", path: "/contact" },
      ],
    };
  },
  methods: {
    sidebar(data) {
      this.pathInfo = data;
    },
    sidebar2() {
      this.pathInfo = "";
    },
    goIndex() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.CommonHeader {
  width: 100%;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 0.7);
  .Header {
    width: 1350px;
    height: 105px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .meun {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      .meunitem {
        width: 80px;
        overflow: hidden;
        text-align: center;
        position: relative;
        .xian1 {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #0cbc65;
        }
        .xian2 {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #0cbc65;
        }
      }

      a {
        width: 100%;
        height: 105px;
        line-height: 105px;
        display: block;
        color: currentcolor;
        text-decoration: none;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333535;
        // border-bottom: 3px solid transparent;
      }

      .router-link-active {
        text-decoration: none;
      }
      .router-link-exact-active {
        color: #0cbc65;
        border-bottom: 4px solid #0cbc65;
      }
    }
  }
}
</style>