import axios from 'axios'
import store from '../store'
import {Message} from 'element-ui'
import {HttpStatusMsgList} from './HttpStatusMsgList'
const qs = require('qs');
const NoMsgApiList = ['/agentrelated/checkAndGet'];

//创建axios实例
const service = axios.create({
    baseURL:"https://www.gzstjt.cn",
    // baseURL:"http://192.168.1.216:5000",
     timeout: 20000
  })
// request拦截器
service.interceptors.request.use((config) => {
   
        if (store.state.accessToken) {
            config.headers.common['accessToken'] = store.state.accessToken
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    }
)

// response拦截器
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let msg = error.message + ''
        if (msg.indexOf('timeout of') >= 0) {
            msg = '请求数据响应超时'
        }
        if (msg.indexOf('Network Error') >= 0) {
            msg = '网络不可达'
        }

        Object.keys(HttpStatusMsgList).forEach(tmpCode => {
            if (msg.indexOf(tmpCode) >= 0) {
                msg = HttpStatusMsgList[tmpCode] + '(' + error.message + ')'
            }
        })

        _showErrorMsg(msg)
        return Promise.reject(error.message)
    }
)

/**
 * get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, param = {}) {
    let urlStr = ''
    for (let i in param) {
        if (param[i] !== '') {
            urlStr += `/${param[i]}`
        }
    }
    return new Promise((resolve, reject) => {
        service({
            method: 'get',
            url: url + urlStr,
        }).then(response => {
            _handleResponse(response, resolve, reject)
        }).catch(err => {
            reject(err)
        })
    })
}


/**
 * post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url,
            data: data,
        }).then(response => {
            _handleResponse(response, resolve, reject)
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 *上传图片(formData)
 *
 * @export
 * @param {*} url
 * @param {*} [data={}]
 * @returns
 */
export function upLoadImg(url, data = {}) {
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url,
            data: data,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.data.status !== 200) {
                _showErrorMsg('上传失败' + response.data.message,)
            }
            resolve(response.data)
        }).catch(err => {
            _showErrorMsg('上传失败')
            reject(err)
        })
    })
}


export function postFormData(url, data = {}) {
    const form = new FormData();
    Object.keys(data).forEach(tmpKey => {
        const tmpV = data[tmpKey];
        if (tmpV != null && (tmpV + '').length > 0) {
            form.append(tmpKey, data[tmpKey]);
        }
    })

    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            // headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url,
            data: form,
        }).then(response => {
            _handleResponse(response, resolve, reject)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getFormData(url, data = {}) {
    const form = {};
    Object.keys(data).forEach(tmpKey => {
        const tmpV = data[tmpKey];
        if (tmpV != null && (tmpV + '').length > 0) {
            form[tmpKey] = data[tmpKey]
        }
    })
    // XMLHttpRequest: 网络错误 0x2efd, 由于出现错误 00002efd 而导致此项操作无法完成。
    // 避免IE请求缓存
    form.avoidReqCache = (new Date()).getTime();

    const queryStr = qs.stringify(form);
    return new Promise((resolve, reject) => {
        service({
            method: 'get',
            url: url + '?' + queryStr,
        }).then(response => {
            _handleResponse(response, resolve, reject)
        }).catch(err => {
            reject(err)
        })
    })
}

function _handleResponse(response, resolve, reject) {
   
    if (response.status !== 200) {
           
        // if (response.data.status === 701) {
           
       
        // }
        // if (response.data.status === 999) {
        //     resolve(response.data)
        // } else {
  
        //     if (idx >= 0) {
        //     } else {
        //         if (response.data.message) {
        //             _showErrorMsg(response.data.message)
        //         } else {
        //             _showErrorMsg('操作失败 (' + response.data.status + ')')
        //         }
        //     }
        //     let errorMsg = ''
        //     if (response.data.status) {
        //         if (response.data.message) {
        //             errorMsg = response.data.message + ''
        //         } else {
        //             errorMsg = '解析数据失败(' + response.data.status + ')'
        //         }
        //     } else {
        //         errorMsg = '解析数据失败'
        //     }
        //     const tmpErr = new Error(errorMsg);
        //     reject(tmpErr)
        // }

    } else {
        resolve(response.data)
    }
}
function _showErrorMsg(msg = '-') {
    Message({ showClose: true, message: msg + '', type: 'error', duration: 3000})
}
