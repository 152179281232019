import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    accessToken: '',
   userInfo:{}
  },
  mutations: {
    SET_USER_TOKEN: (state, payload) => {
      state.accessToken = payload
    },
    LOG_OUT: (state, payload) => {
        state.accessToken= ''
    },
  },
  actions: {
    setUserInfo ({commit}, payload) {
      commit('SET_USER_TOKEN', payload)
    },
    doLogout ({commit}, payload) {
      commit('LOG_OUT', payload)
    },
  },
  getters: {
    isLogined: state => {
      if (state.userInfo.id != null &&
                state.accessToken != null &&
                state.accessToken.length > 2) {
        return true
      }
      return false
    },
    
  },
  modules: {},
  plugins: [createPersistedState({storage: {
    getItem: (key) => {
      const tmpStr = sessionStorage.getItem(key)
      if (tmpStr == null || tmpStr === undefined || tmpStr.length <= 0) {
        return {}
      }
      return tmpStr
    },
    setItem: (key, value) => {
      if (value == null || value === undefined) {
        return
      }
   
      sessionStorage.setItem(key, value)
    },
    removeItem: (key) => {
      sessionStorage.removeItem(key)
    }
  }})]
})
