import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({

  routes: [
    {
      path: '/',
      name: '首页',
      component: () => import('@/views/home/index'),
      meta: { keepAlive: true }
    },
    {
      path: '/Home',
      name: '首页',
      component: () => import('@/views/home/index'),
      meta: { keepAlive: true }
    },
    {
      path: '/about-us',
      name: '关于我们',
      component: () => import('@/views/about-us/index'),
      meta: { keepAlive: true }
    },


    {
      path: '/business',
      name: '经营范围',
      component: () => import('@/views/business/index'),
      meta: { keepAlive: true }
    },
    {
      path: '/enterprise',
      name: '企业力量',
      component: () => import('@/views/enterprise/index'),
      meta: { keepAlive: true }
    },

    {
      path: '/news',
      name: '新闻资讯',
      component: () => import('@/views/news/index'),
      meta: { keepAlive: true }
    },

    {
      path: '/news-details',
      name: '新闻详情',
      component: () => import('@/views/news/news-details'),

    },

    {
      path: '/company-details',
      name: '公司详情',
      component: () => import('@/views/company-details'),
    },
    {
      path: '/announcement',
      name: '通知公告',
      component: () => import('@/views/announcement/index'),
      meta: { keepAlive: true }
    },
    //
    {
      path: '/notice-details',
      name: '公告详情',
      component: () => import('@/views/announcement/notice-details'),
    },
    {
      path: '/software',
      name: '晟泰软件',
      component: () => import('@/views/software/index'),
    },
    {
      path: '/download',
      name: '文件下载',
      component: () => import('@/views/download/index'),
      meta: { keepAlive: true,  isCmpHidden: true }

    }
    ,
    {
      path: '/contact',
      name: '联系我们',
      component: () => import('@/views/contact/index'),
      meta: { keepAlive: true }
    }
    ,
    {
      path: '/registered',
      name: '注册',
      component: () => import('@/views/registered/index'),
      meta: {
        isCmpHidden: true
      }
    }
    ,
    {
      path: '/login',
      name: '登录',
      component: () => import('@/views/login/index'),
      meta: {
        isCmpHidden: true
      }
    }

  ]
})
