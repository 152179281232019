export const HttpStatusMsgList = {
    '505': 'HTTP 版本不受支持',
    '504': '网关超时',
    '503': '服务不可用',
    '502': '错误网关',
    '501': '尚未实施',
    '500': '服务内部错误',
    '400': '错误请求',
    '401': '未授权',
    '403': '服务器拒绝请求',
    '404': '访问的接口不存在',
    '405': '请求方法被禁止',
    '406': '无法使用请求的内容特性响应请求的网页',
    '407': '需要代理授权',
    '408': '服务器等候请求时发生超时',
    '409': '服务器在完成请求时发生冲突',
    '410': '请求的资源已永久删除',
    '411': '服务器不接受不含有效内容长度标头字段的请求',
    '412': '请求未满足前提条件',
    '413': '请求实体过大',
    '414': '请求的 URI 过长',
    '415': '不支持的媒体类型',
    '416': '请求范围不符合要求',
    '417': '服务器未满足"期望"请求标头字段的要求'
}
