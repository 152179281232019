<template>
  <div id="app">
    <common-header class="common-header" />
    <common-carousel v-show="!$route.meta.isCmpHidden" />
    <!-- <div class="Content-Box" :style="$route.meta.isCmpHidden ? bgurl : ''">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div> -->

    <router-view></router-view>
    <CommonBottom  />
  </div>
</template>
<script>
import 'animate.css'
import CommonHeader from "@/components/CommonHeader.vue";
import CommonCarousel from "@/components/CommonCarousel.vue";
import CommonBottom from "@/components/CommonBottom.vue";
export default {
  name: "App",
  components: {
    CommonHeader,
    CommonCarousel,
    CommonBottom,
  },
  data() {
    return {
      bgurl: {
        backgroundImage: "url(" + require("./assets/Image/login.jpeg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        position: "relative",
        width: "100%",
        backgroundPosition: "center center",
      },
    };
  },
};
</script>

<style >
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
/* .common-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
} */
#app {
  cursor: default;
}

/* .Content-Box {
  width: 1200px;
  margin: auto;
  padding: 20px 0;
  box-sizing: border-box;
} */
/* #app {
  overflow-x: scroll;
  white-space: nowrap;
}
#app::-webkit-scrollbar {
  display: none;
} */
/* 


#app::-webkit-scrollbar {
  width: 0 !important;
}
#app::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
} */
</style>
